/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { graphql, Script, Link } from "gatsby"
import { BaseStyles, jsx } from "theme-ui"
import { Helmet } from "react-helmet"

// components
import ComponentContainer from "../components/ComponentContainer"
import Layout from "../components/Layout"
import Metadata from "../components/Metadata"
import MarkdownContent from "../components/MarkdownContent"
import Button from "../components/Button"

// utils
import { addMonths } from "../utilities/formatDate"

const OpenPositionTemplate = ({ data }) => {
  const position = data.markdownRemark.frontmatter
  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">
          {/* The required fields for structured data -> https://developers.google.com/search/docs/advanced/structured-data/job-posting#job-posting-definition
              - title
              - description
              - datePosted
              - hiringOrganization
              - jobLocation
          */}
          {`
            {
              "@context" : "https://schema.org/",
              "@type" : "JobPosting",
              "title" : "${position.openPositionTitle}",
              "description" : "<p>${position.openPositionDescription}</p>",
              "datePosted" : "${position.openPositionPosted}",
              "validThrough" : "${addMonths(position.openPositionPosted, 2)}",
              "employmentType" : "${position.employmentType}",
              "hiringOrganization" : {
                "@type" : "Organization",
                "name" : "ACORE CAPITAL",
                "sameAs" : "https://www.acorecapital.com/",
                "logo" : "/uploads/ACORE-Logo.svg"
              },
              "jobLocation": {
                "@type": "Place",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "130 W 42nd St",
                  "addressLocality": "New York",
                  "addressRegion": "NY",
                  "postalCode": "10036",
                  "addressCountry": "US"
                }
              },
              "baseSalary": {
                "@type": "MonetaryAmount",
                "currency": "USD",
                "value": {
                  "@type": "QuantitativeValue",
                  "value": "${position.salary}",
                  "unitText": "YEAR"
                }
              }
            }
          `}
        </script>
      </Helmet>
      <Metadata title={position.openPositionTitle} />
      <BaseStyles>
        <ComponentContainer backgroundColor="white">
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "50px",
              marginBottom: "50px",
            }}
          >
            <Button
              text="Back to Careers Page"
              link="/careers"
              buttonVariant="grayButton"
            />
            <br />
            <div sx={title}>{position.openPositionTitle}</div>
            <div sx={experience}>{position.openPositionRequiredExperience}</div>
            <MarkdownContent content={position.openPositionFullListing} />
          </div>
        </ComponentContainer>
      </BaseStyles>
    </Layout>
  )
}

export default OpenPositionTemplate

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        openPositionTitle
        openPositionPosted
        openPositionRequiredExperience
        openPositionDescription
        # openPositionPDF
        salary
        employmentType
        openPositionFullListing
      }
    }
  }
`

// styles
const title = {
  fontSize: "32px",
  fontWeight: 300,
  color: "black",
}
const experience = {
  fontSize: "23px",
  color: "primary",
  marginTop: "10px",
  marginBottom: "10px",
}
